<template>
  <div class="about">
    <h1>Upload Test</h1>
    <v-file-input
      accept=".pdf"
      v-model="file"
      truncate-length="20"
    ></v-file-input>
    <v-btn color="primary" @click="upload_file">Upload</v-btn>
  </div>
</template>
<script>
import axiosService from "@/services/axios.service";
import axios from "axios";
export default {
  name: "About",
  mixins: [axiosService],
  data() {
    return {
      loading: false,
      filename: "lol",
      file: null,
    };
  },

  mounted() {
    console.log("About page mounted.");
  },
  methods: {
    async upload_file() {
      let result = await this.aws_presigned_post(this.filename);
      if (result.ok) {
        let policy = result.result;
        var formData = new FormData();
        formData.append("key", policy.fields.key);
        formData.append("policy", policy.fields.policy);
        formData.append("x-amz-algorithm", policy.fields["x-amz-algorithm"]);
        formData.append("x-amz-date", policy.fields["x-amz-date"]);
        formData.append("x-amz-credential", policy.fields["x-amz-credential"]);
        formData.append("x-amz-signature", policy.fields["x-amz-signature"]);
        formData.append("file", this.file);

        axios
          .post(policy.url, formData, {
            headers: {
              // "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
            },
            crossDomain: true,
          })
          .then((response) => {
            console.log(response);
          });
      }
    },
  },
};
</script>
